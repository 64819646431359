import React, { useState } from 'react';
import { ethers } from 'ethers';
import { OG_WALLETS, FCFS_WALLETS } from '../config/walletLists';

function WalletChecker() {
  const [walletAddress, setWalletAddress] = useState('');
  const [ogStatus, setOgStatus] = useState(null);
  const [fcfsStatus, setFcfsStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const checkWallet = async () => {
    try {
      setLoading(true);
      setError('');
      
      if (!ethers.utils.isAddress(walletAddress)) {
        throw new Error('Invalid Ethereum address');
      }

      // Convert input address to lowercase for comparison
      const normalizedAddress = walletAddress.toLowerCase();

      // Check OG status - convert all addresses to lowercase for comparison
      const isOG = OG_WALLETS.some(addr => addr.toLowerCase() === normalizedAddress);
      setOgStatus(isOG);

      // Check FCFS status - convert all addresses to lowercase for comparison
      const isFCFS = FCFS_WALLETS.some(addr => addr.toLowerCase() === normalizedAddress);
      setFcfsStatus(isFCFS);

      // Log for debugging
      console.log('Input address:', normalizedAddress);
      console.log('OG Wallets:', OG_WALLETS);
      console.log('FCFS Wallets:', FCFS_WALLETS);

    } catch (err) {
      setError(err.message);
      setOgStatus(null);
      setFcfsStatus(null);
    } finally {
      setLoading(false);
    }
  };

  const getEligibilityMessage = () => {
    if (ogStatus && fcfsStatus) {
      return "OG (Free) and FCFS";
    } else if (ogStatus) {
      return "OG (Free)";
    } else if (fcfsStatus) {
      return "FCFS";
    }
    return null;
  };

  const isEligible = ogStatus || fcfsStatus;

  return (
    <div className="wallet-checker">
      <div className="input-group">
        <input
          type="text"
          value={walletAddress}
          onChange={(e) => setWalletAddress(e.target.value)}
          placeholder="Enter wallet address"
          className="wallet-input"
        />
        <button 
          onClick={checkWallet}
          disabled={loading}
          className="check-button"
        >
          {loading ? 'Checking...' : 'Check Status'}
        </button>
      </div>
      
      {error && <p className="error">{error}</p>}
      
      <div className="status-results">
        <div className="status-box">
          <h3>OG Status (Free Mint)</h3>
          {ogStatus !== null && (
            <p className={`result ${ogStatus ? 'success' : 'failure'}`}>
              {ogStatus 
                ? '✅ Eligible for OG (Free) Mint' 
                : '❌ Not eligible for OG Mint'}
            </p>
          )}
        </div>

        <div className="status-box">
          <h3>FCFS Status</h3>
          {fcfsStatus !== null && (
            <p className={`result ${fcfsStatus ? 'success' : 'failure'}`}>
              {fcfsStatus 
                ? '✅ Eligible for FCFS Mint' 
                : '❌ Not eligible for FCFS Mint'}
            </p>
          )}
        </div>
      </div>

      {isEligible && (
        <div className="congrats-message">
          <h2>🎉 Congratulations!</h2>
          <p>You are eligible for {getEligibilityMessage()} mint!</p>
          <p>Join our Discord for updates and follow Hyperdogs on X!</p>
          <div className="social-buttons">
            <a 
              href="https://discord.gg/MrskFcRHPy" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="social-button discord"
            >
              Join Discord
            </a>
            <a 
              href="https://x.com/hyperdogshl" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="social-button x"
            >
              Follow on X
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default WalletChecker; 