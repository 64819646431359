export const OG_WALLETS = [
    // Add OG wallet addresses here
    '0x1c2f091C9560Ee8Cb7012b4c32995F573Be9eA8C',
    '0xf4c2398e263E83f9495b77225B9cDC6069127c55',
    '0xeB97f37e7065A50a46f5DDEfc49e0419aBb931Bd',
    '0x500031Df81d8BdF757E3D21cc1a396212264E488',
    '0xAd90f2350466104180049d2970712DF8352C6B59',
    '0x49f23D7891D148846faa48107945a4A7921a95bA',
    '0x72b378103929D2412Ee63323A152aCf47380a4bF',
    '0x6C60280a5562761F77938b3B5C6bA6EaB878f95D',
    '0xaf310Ce1C06128d1d0Cf6f5808e830eAc2FC9f8E',
    '0xa468f8bEe9D373897136d9FD3c298BB2155a3605',
    '0x0403749978f93f90be0b1e2294af081e42d5fdbc',
    '0xb73d6d13b980e0feba8f9ca4d370bf7bc8d64a44',
    '0x1d1acfd307417a946177f5b2c08de624182ef83c',
    '0xdedcdbbec105ec20c115968dcfe18669c73ea287',
    '0xc46200731b2a56b423f818c53f66025ef5ec6d7a',
    '0x5f3ab3a0c94b2a30b9908a589a2e78e826e1e6ee',
    '0x08f6f29ea7b285c1f6f984ae33a6fdc55522e00f',
    '0x3c797c356ead723692b8b646a8defdb13a309286',
    '0x90008b62b27ee14bf2948233f529eafc432638cd',
    '0x12df29b1675eeec83d14f7e771da9533ffc4a9e2',
    '0x8b4218bc20285160189ebcfe9669937667dcadaf',
    '0x44f8d07812f917793b67771bc1fc54699d26d838',
    '0x5754031ffdb25ebb367fe30fbc04c3215f85a46a',
    '0xb6fe9ccd04b0b329aaf0795312f9f7d86372941c',
    '0x84051571071339483d4567a3b90c8b23db37bb57',
    '0x8ac6e7f039bf4f0887a5c1f76e8a0561bb7b87e9',
    '0x8359a877f4cae6c37f3fd99fa7672012f15db68b',
    '0xbe13777cffb89dd8611e41c8b3c02eabf119f896',
    '0x0b795cf99008cb67b72e5b17625307ca6f80fb70',
    '0xbffab2f11683cfbd05783c5d0102b5261f057a1b',
    '0x7e70df7b9821b6e0aa9d67f677d937c5d7cf2a3c',
    '0xcd43aacb947ec2dbeccebeda780f31f3e03d79ff',
    '0xa7e33cc77474f63094e3e3b6e79dfa71fde87474',
    '0x970a00520cdfc1a0888e3e55aa7114e9640f0f80',
    '0x149421136d0c2cffb590e3f8f0cb4cc5415335b6',
    '0xf718e7e50e049f93cfc391f9d573c2930d2910f8',
    '0x500031df81d8bdf757e3d21cc1a396212264e488',
    '0xaf1230261c8b36858c148ad2d064e5fb162f4314',
    '0x032fcf42c9b2ca62157cbeab322cfd8004ee08fe',
    '0xd38ce04edd388cf03234c84c2d49a9f64a0faf59',
    '0x531281c5e8dad32464996398136e35bfc26c080f',
    '0x365c3e51bdc17f6c8b69073b9d11aa3d297a5ac8',
    '0xb30dadd3b62d3f63c23578208d07d47a8ff08531',
    '0x8c4e9e5dcc3a2c30fcfc54128eeed7533bc837b9',
    '0x692464bd211722303017d1df788ac8cc744ddc82',
    '0xc0c358d78801b47ddb08eecacf11aa1bd79d8e06',
    '0xa9e8d8c0598362926795a0b563a7ee1ebca83f6a',
    '0x49f23d7891d148846faa48107945a4a7921a95ba',
    '0x57c7fabaa998fa7149134a3a6ce832da1753c415',
    '0x69aae7a2969d5ef1a6521ed2f2cc68b9d16360b3',
    '0xa0c00ff922f11ea31979f99e88676ce06f2573a2',
    '0xf9bbe587159d3dd2379cb0024edfffaba2570863',
    '0xf438aee9feb48f548722b4e02dccad8bf2ab760b',
    '0x0b4bf8ef782e742792eb93d2b34dff655fabe804',
    '0xb5ce737399188c7a9cd027bf6e8d8b88cd4c5b8b',
    '0x91961afcc32a8a120ff103780bef0d44ed0415a2',
    '0xcc71e757aa06c95efae78e5b4f4872628ea77d3f',
    '0xb63c717ffc9a3ca8e89fcc09a1dc44d727ce93ac',
    '0x9b1c8825b4e4dacfb3c86a7ef8fd5cefd026528b',
    '0x7a4cfcc6dce683c8eeb8af7ca06109eee0f4e664',
    '0xd599f49b24409339708df607f8e89a7950fb76de',
    '0x9d38e4e0687dadc7786286ff23b85c9027e9e397',
    '0x27c65a888cedf5aecb830496dc83de0658d5f9a9',
    '0x4769c3c291fcf153091a4fd3afc88e21d847e85d',
    '0x249446600be691228c6ccbdb34e545681d23399c',
    '0x918e5b0bba7d46e1bca8c5d7152b7f8b31a4057c',
    '0x9d8e40bb37054fb5e3ca5d59094cb9402b8f10c2',
    '0xba53a3c15ccb1e4387969cdf865cd19eef9c1f9a',
    '0xa8c4eba29fb34aa1c4c2da0ef07c2a71d8486651',
    '0xbe48cdba8463c5003fc1c9f90cb51f056125153e',
    '0xeffedfb07e4d683fc7467e2be0cf2a151e0f7f7a',
    '0x4792482399c6110c817909c7fab668384e857d9e',
    '0xfdeb3fbaffbcf798415b30d0815c504dbfcba758',
    '0xb479ebcd7007e61be261104d31302d93c163931c',
    '0x67c39e8887838abf8a4040071088dc7a9dc1bb9f',
    '0x7a80671cda7ac58b305adfffd95235a077128743',
    '0x35bd84dd9c90e9df0dfeb476fe116615cecd5b0d',
    '0xfedd203a66f6f138203c9a96cd0ae165f9177541',
    '0x8e92c035b4f535351b0b9c537c39afa3c8a6a2a7',
    '0xd434ba101cd7469a4fa26b2e031345aafcc8e555',
    '0xbc81d19e0c6abe8e7dea95e3ac9892ffeb1039cb',
    '0x20485a81c3df6c13ab6317690447c9553c2cb440',
    '0x102e788e8f5bb9d03919a17338544eb8e3e2beb0',
    '0x5ad25ce82af276c5f0b000fc44414edbd3845565',
    '0x3af2edc9a46766de9f661b3ffc05910cc993b7f7',
    '0x80fa74709ccaf281a390d203ed3e36e840f7e7bf',
    '0x929089f0731a4c93dad509a8fd8bd2f92493c2e8',
    '0x2fbedbca46c5b1cdd2c936402a78fe236d72a009',
    '0x5f2d34b82bbf1a75c2c617988d475e6485004303',
    '0x11da73ed9d4fec364e2eba05d35111628f511058',
    '0xa8296284049e2a1aeced8701a16d5e2d9001f495',
    '0x356f48882321e78f9d3145e12a64f1821b85f80d',
    '0xf478bebf61f1be01d927579314717cfb0a4a7e0f',
    '0x03725188838aca50f8c93b700be40f40181ea0cf',
    '0x535c1bab9a92fa2e361cbf5fb0c6e802e6095591',
    '0xa5b43bb37bfe0d3b9540a329412188673ee3f37e',
    '0x05ede8fa563a048600db1696e447cb3e29610ea0',
    '0x2ef4bd2d00201c1cca89c088cca12dfa647c2874',
    '0x989cd16ae387a932aa3fa900fc01fbc682979adc',
    '0x8ac959936946153d7b500e184c70f118c100ec93',
    '0xc8a91dbf0c145c5d4f7d27b1093fe1055138e989',
    '0x3f5e260d6e05c16adf319e5b32dfe7cffb5efaea',
    '0x9bfc0c7574fdaa89f4d296678a5824bf9f9af443',
    '0xf4176e4c0c5d84af3fa31626ead9638a8bf43459',
    '0x83b9124e8f4105afe77e91197ef2d78d9e39756b',
    '0x19a92b57ea07681de72ac114af93921b0b32fb71',
    '0x57464cdaff16089bd8ec8f2ac9e01ef595e511f2',
    '0x70ff3c006f388a345ccf4b8096c9d8c610f436ea',
    '0x42d8d967ae723849b1acd8ddccdc5efec3083ec4',
    '0x5b1de2412609cf421e340f63eb0b5ce3b4ce18c7',
    '0x27d4d0962645d775a7324c5620311567aa2562ea',
    '0x4c153e809c73dc9b81b64ee0c6a62b38c4c2e4c4',
    '0xe43bee4f5cc36797e62a51b61028895f7448a0cf',
    '0x0b3a7c39c272d24bf9c9335285128efb9cafe6ce',
    '0x4adfefe3b720e8231b3b3c8e4af2e2ff3d1497a0',
    '0x42d12551a7e4d913c80bd3d75e69d7d2d4c1d043',
    '0x890a3fee0a6c2c38fbf6beed37cb963319e2b8ad',
    '0x8ec3a45364b694e0142acb94ecc4ed914ddf9918',
    '0x3dd8389e7f1cefa24b6c34a6c1e621672f45144e',
    '0x59eedd58c849233b647db353958ac05befbbba50',
    '0xbd188672bbc7bc97069592a8824d3fb6740e1d4c',
    '0x470f1f68e8bd2e188637568a9e55eb25040860b8',
    '0x27c0a41fee038e7dac121cf9b6a85668b9a656c2',
    '0x9d4fc4e3210d3542aa77712602b236a4d083ea48',
    '0x5e71451fa6a895490e056b5ffd24c4226010d390',
    '0x6987136b4955e9a76da6dd45a12b55e1e1827c61',
    '0xd74c27610d907d078de0ac2024a4a159407e4e9b',
    '0x22639f183805a9c017bb575eac3343235d9eb32f',
    '0xe53089e0b4bfe827c8b3f1466bf81bc861c6b8ee',
    '0x8ce6a8ea2ad6088c86a6d33c953563239db57709',
    '0xd56bb52644f9f01a1e31dc1973a66635a968900e',
    '0x532250702b17977c8bb80b11a8a3bc594a926ef1',
    '0x61ca377e98e0468d60b4971a2ff51a7fa840ae04',
    '0xe10aef7f37e35452ec2c2e74fc03ae7f7c516ff1',
    '0xda66f4ad311ec61999125a9f4b1d12509173c370',
    '0x64ba47c1e4aae117adc244badbb77c03d53f024d',
    '0xe64080ba951f7730f9a575aab952df9694c66531',
    '0x1233ed22d9afc05eb666621eeb99487b2560a12d',
    '0xb3249cf565cab84a08d26aae279c5f992110e894',
    '0x475d2c9f5788a8779515e248c27d42f944c27bd8',
    '0xa2ef93cfb72924ac13f6523f4680e6acb53c5c4c',
    '0x96349e18b094e7186f952ba5357cb21a2dca58a0',
    '0x25afbb624650a40d3d2cace395adb13b0912dce4',
    '0x95cb2675e999b42ea26c14cad5d77610f7233418',
    '0x4afc64ea8f5c3a8f80a47d64a3f74ca08cf5dd40',
    '0xab29e4d0a169b3241ca6525f0dc95062bc9326f4',
    '0x088058855ae7ad6315c19695e289b6ed38c900a6',
    '0x1e999dc03517419fadc883784976e794f2679055',
    '0xdd793e2589e523012aceae054473fe86ed8eba0a',
    '0x59dcc01a492d85a0bf244114e7268d6f232348e5',
    '0xbf6534a55c95d63f33d0cd01cc5b8cc2baa15cf8',
    '0x0c7a4a1bff94fbf2c5b24407f75591de637f061a',
    '0xa00df68e19075f960e5fd760691ee81a8c72e5d4',
    '0xa2533306df9989bf13dbff3c387e9454dc5dda66',
    '0x5b8f7eb9db71fe86731e8dbf1efd92985e31e86c',
    '0x0d58167d5af2282ebf4af2fa1e1d34c3d9631e9d',
    '0x65652fcbf273eedbe85e89fca6fd02ab910615bc',
    '0xb108020aa10120d0ce2105e2c5ae12007ce4606b',
    '0xafca929a2b4318f59520f55f12142ab909291db2',
    '0x02dc7bc28f759a7b25bed21146c977260a4bbd65',
    '0xb86cb2999ab045204b8a0736364d079a87368787',
    '0x3ffc345b1e0c6a6f2374ea418745f1bbac0682b8',
    '0x39f2a6d827f1ef120809adcc7ba66f06fc78a155',
    '0xcc1be723c0c4a2eda128b01086da0b0f4d3fb0f1',
    '0x06e8f717fc5f1819703cbe3a7f9aa4a7f695bc31',
    '0x04c08cd6e6e40c29c8cb2617dcb64e2f63c1645a',
    '0x69a0588b1740c92c045cc419d9613464bc5df8e5',
    '0x95850b1d6d396ed77a8c84d4b55d07e8f04d87e1',
    '0x7d3aef4e1e569595fa217f210dbc1822e0011fa6',
    '0x873381509fc848f9d67ad236e6eebfc295ad6fce',
    '0x407ffad1d0a3c2aa72220e0d1d046ecdb08452d3',
    '0xaf036038340ca541dd4155052273290354d6e0ce',
    '0x22f923fc106fc913b9e68437986e0b9d19d6a038',
    '0x853fbe429740146669686e3d1c3122b0c28ebb87',
    '0xc15756683bf4fa5c4aa66954eca14ed982ef1a31',
    '0x636d349cb5dc5faf8b7835b3c8578b8090baea20',
    '0xff18451c65d3f149230287d46073fe48f2bead63',
    '0x552688dc1839907913a353fff74fb46acbbbbf59',
    '0xbc0fed4d83ab0fe59a52e44095d8af36fe1fa1c2',
    '0xf8583be398fb2c554396489ac5b9e1dbdfd2a6bc',
    '0x5c5e1582660f4cb26a2c805cb75a8012bcce9a32',
    '0xb2df86b88e2931c48d7e91a3f2d1fe14a5167dd5',
    '0xe1a5f74ba4b6f128832f8ab8a1590a91d1b17867',
    '0xd08b7c5472e25be5413a33e4474f9255510d6a69',
    '0xaca3490ec57109a3c45e92de9b3cdb31ee8279ff',
    '0x40862f05b7d60795492ae1d9f4896a3aff40e0f7',
    '0x256c07dfb2c3fbcbbd85b4433bb771277f81c6c6',
    '0x74075ad4ce2ba2bb545f438899e8c40ee2a8dc46',
    '0xd5d7e10d3210f38773c29391bdc9c437483865d0',
    '0xe851b5adb066f6002823c69ba1864090acd78a3b',
    '0x6326307697af39a5e592aeeba44df279405b0d49',
    '0xa27b53697c2378838dc5aeee211146f44e2b2955',
    '0xb79a0c31257d3956d03400c158af9272af485cbf',
    '0x63428356f0e0eb4512319a7ed4ffe2adc590ea0a',
    '0x525a4e205610ca2f10f4cea5e8624785a90881d4',
    '0xd1870943aee05a65cd4d997f9e885c365737d60b',
    '0x259535aa24f944ec0b071a7880a7c5909cd4148e',
    '0x4f33c8fe4861afc444f759cba32a021e52d93c63',
    '0x3bd1682d736bcdee6e78583be843319e8075e198',
    '0x1ac1a147774eb4b778e19a161e881fd6fdc9800b',
    '0x74efc6868f473099033382152c157f258c03c01a',
    '0x7baa6f465dc4302fb958a92e636a6f6b09b2356b',
    '0xd57b28d589a95816b8d99e537c9c98987ea42185',
    '0x2ebce640d334ea329c351eae06fccefac18178a2',
    '0xbc3917d027e5420c9978c52f6679eee9d5adfc41',
    '0x60288566385502169f379a879ede735c8275d0e4',
    '0x49d834027cce433a7ca5ea2a81a675f670ab6437',
    '0x19efd6247f3365d0eb45192c712b1e8a01ad58d1',
    '0xace39898231986e8dc6d3bbfb1dc27d1e2a1baf3',
    '0x06d41bcbcfe1db433045bca895189eb8e60591dd',
    '0x15413cc2b3b2afe050443b71ad2207ac03c27753',
    '0xed2081f5a4d5edcdd9e450681ced18328e163c1f',
    '0x8f7099946e8fb050be82d37981fb34448e6bd22f',
    '0x8bfb70ad55ce0ae8ff86ee653353a5237f97792a',
    '0x9a3920f1bacfd42c8412d97011e1134d0cbaadfe',
    '0x9a31ab36ad52036cdaaff0e42ead962007051de4',
    '0xe088ac0deac81346d6553123533814b40528082a',
    '0x58ed78e53ef5c02e837f1533e197e6b0978f7865',
    '0x9d75711be427c90d34acbcf362553cb6e80b7f54',
    '0x501de9200220997ef530800838cf2d49bc7b270e',
    '0x8ee61b437b29ecebf4c5e8fa20de946da1211700',
    '0xb384d352db729808db778c72e445f042bca1495e',
    '0x7437604250ef6b2b4a4648666cb63d2486955388',
    '0x9a2be895e04fc706d8d985323ff420f2ccdd7677',
    '0xfea4d3b2ae1a3c4a0c8e0b2e409111b8174dd522',
    '0x8c291fd0dbf06fb1f0583addf57d5b35680cefc1',
    '0xcfb4e67c80add3078cf085c8d1977dc75e1365cf',
    '0x9a3bf45836399d37ff02f6376d214101dee0ee73',
    '0xdfdf9a2537112e766f176e54745cbb8d884eeef5',
    '0x2fe17c83b1675604ece06bf24885d19719dcb9bb',
    '0x024688dbdbb8cd8e2a3c041f9fc2c4209ac27790',
    '0x9feb683598f6210b9f0fa4a00cbc51de59cc8ff2',
    '0x6e15483291517e5f4970aad3d85174ae5e476669',
    '0x138f01cdd5faa1e09550706bd3b7e0e45b5f7c0a',
    '0xfcfee831095c0bafde86ac784c57cfd66b59ed32',
    '0xeb68fa9eca353e13bdbaf07677322b0580ad8a2b',
    '0x51b236a4cd909553ea667422e66fab6509f1f5c0',
    '0xcda03ae049a66e723c7d3be89cd3007382326685',
    '0x7cec4fa4eb6c7a66b57a3aafc904b97fb4e20419',
    '0x793edb4ed5a55339af3552cab67add1e9733b3e5',
    '0x1d57daff1db7c5f82a6af483041cf1d687501874',
    '0x8be609b5e0eb292e62d3a509ff5fef43b926f532',
    '0x76e2307e764f6e4e98fbf6ef282d5af7ef5c3552',
    '0x0dd65e49325457f41f06b95e3d95a051e9fac99e',
    '0x655eaea2e79ca0242d60513f39d743d1d9ac63ba',
    '0x94b7d7c750e397e6a17f1edb3efc0a03daf534c1',
    '0xcb408fb7514b7a06018794a7da76c489eb4e8ab6',
    '0xc694d7e8bc3f146af1f87e6d6e431664e52f2e56',
    '0x04f46514d07a9bdb8e598466590a2da4e9633caf',
    '0x5de76f0c59f5bfe0640507c6308ac5905f4ac35a',
    '0x1378023761aeb03b0d6e1981fefc55cf6919b6c0',
    '0x2fcf6c65dd9248bc9387bc8e1d04742110172db1',
    '0xb413251bb8eddfe94bc1abe08fcfc7c86cd1b5d8',
    '0xdcbe38b343d28eda0a751d31e0d51826021d462c',
    '0xebf8d9fa3dcac42986db707f0220cbe580976998',
    '0xb99256867abcc0716c871498bdd351f17792aa8c',
    '0x428164ecda467cbd2104be7623d3aa140f938b96',
    '0xb94cc2626429c896df5d7e19da0f064d21b06098',
    '0x029d1f64ea23b26e1b2dbcf300df1c2a60bf7167',
    '0xa394b6c980750cfc1bf1e7f88659446230730348',
    '0x74f01282c959ef874e113c69b317c65c2d6c15c8',
    '0x742721d7ddc0357dc38a14a563290c2cd20edd6f',
    '0x8e19fa0f6780dd5c26bb42177d246702e7612889',
    '0x5fab78cfebb5fe5fd528ef2f374d4d7765ced06c',
    '0x0411586e0635fcaf1c0d79d84497ce8c572a04db',
    '0x2732c43e08ff6722495eceb96bc61fea660d5b96',
    '0xb7b46588707c4fa7dceebdbfa2b1e5a2f199fa57',
    '0x27d30b688d0f315b2c70e2e0bfc06b57738e40a9',
    '0x0238d644fbe372b3fd4fa02d3b9992223b1e544d',
    '0x7912ab56a4a13cdeea463d47137b8b289970f714',
    '0x26784995441f4fbaca64831c3188ce11f3a3b6df',
    '0x5f1c0202ad06ef277421dd46e8ddff35b3cbf321',
    '0xf754707ec4a4e12468a1d53abc28389f35516cfc',
    '0xcd6deda719b5218502f1c465b9d92454d53b4804',
    '0x8c7080e8d94ff17d8ebf2fbb3dedcb597649e726',
    '0x93bb680bc2beaa22b1ab6635ccc88cf34651071d',
    '0x6e378eee86707835bcc5beaf25fb82492d542c40',
    '0x1a7ccae5ccaed579710064f63ced8d71759f91ae',
    '0x933b4f2a3595e6bc10ed966c5047f3f246b0b98a',
    '0x499f7ca11aa99578e5bab2ab7b9b68c2785c9e89',
    '0x831bce63d369a822a54a113832443e47c0851644',
    '0xc116fdb1e5c909c947b1c75b206727b6b3d65d86',
    '0xe58947226ca90912804f074776c7a9f3661f0469',
    '0xdbd554258a7978e52326778969930e9e57b4107d',
    '0x9b98d1a401b503f6ffcb767f7984ac3bd5b0a3a4',
    '0xa4db4124fa0511f30108313a2b338b14c5637e9c',
    '0x1b542594e7b402bed026f26c5908ef096e716c14',
    '0x43c88a69ee6a2eba0fb8f2de69729844201ebd07',
    '0x5f834dbcefe441192402b1095b4dca57d13ff1d7',
    '0xb9bbe1251bced7df0e57ffd892dc5e40785ca504',
    '0x2c5ac2428023e728ba2881e4dab035638ee01dd5',
    '0x39289c85007307e74ac73002845611ff8e9f15a0',
    '0x152ee82f100b807f53e986aea39aac9dc95c4805',
    '0x4dcf7388cf6fb4cd3784f7c90915a859e39b3512',
    '0xe1d048d44e39b3d3ee772265c5542629679f8473',
    '0x2fd876afa0ddd24407a5b1ce1cf6c81b338baadd',
    '0xdd1df020a62a41fe7f47e952b7efb4eba6f68522',
    '0x3116999d8dbdc2b0f1ee6ffaac6189760f69294a',
    '0xccb579b0e98588aa5b1ccbbdfe27dd32066c31af',
    '0x549dfdb75bdfd717cffd72137b8654e02cf9730a',
    '0x3d108072207637752167b07c3b25e5db3591a793',
    '0x24d4b75e9138ee32e408407a5a2a678dec7cc6ea',
    '0xd21e7e42b589f3fc7d2a6abb6f25208f6413c996',
    '0x3d3f87483e3be087dd815480d7f14b402184c75d',
    '0x841eebec65eb0ea3f46ae87c20a17151b4c3160b',
    '0x33e24d33405a63411cafe00997b8e52b10c8dcd9',
    '0x8f28eacafc69be05d91cf380c8501a33b85e6e9e',
    '0x2589a5a6524c96c6ebebe267266bde292991d55a',
    '0xe21d20a400130767200e7b1725495220a4e9bd54',
    '0xaf421ffd66834a5c10df13682e84013e2d4600a8',
    '0xfa87403e3af333161c41fd32853f134f0e540014',
    '0x42f8d3ededd352d69d1b1a5202ca43a2c7b0cc7a',
    '0xa9945301d215138d695d5fb82e61c4b1040494f1',
    '0x55815a8a656a7df9fc9bd88439edca4ed15884c2',
    '0xbc25e7ef67d05cb73499a98adb2d4298179f149a',
    '0xf65b82f6d26b4711dbd3078832d823679edf0d66',
    '0xc91edcf40de7224bfeee770d45824d5746120560',
    '0xa1aa67a4d8d030db254077ebbad86ee95b8b45ef',
    '0xe28acd14d3f4e17d14df4f1f0ab0a8fb5a3640eb',
    '0x10d4f7d7ddaee4588a174cefb7bd33d5c8f4741f',
    '0x22701ec5510556883fec4840c9e2288f14a756a4',
    '0xfd2e3d7abf039796417e45b65702d83a5e0fdee7',
    '0x0c4cac225dd3920de625a34e4c935ccc4b3be597',
    '0xf5a7109cc218cbbbbff9d229246a0e01587cebf8',
    '0xe083951e96832033ea63f75d2a77530f692f03c3',
    '0xece72183d6babc796a06517f68a15f35f9ed0434',
    '0x3bda68a223271d343b62ff15ed338ddaf9b82160',
    '0x5138dad7566fed206089dfd7e8b1b1fd4f2a821a',
    '0x1e8917408457b5aba5958795a4d6377b51253e86',
    '0x4d1c2fce08aeaf607e3012335c347eb180e022f6',
    '0x9745eaaf986e7eb072a104d805a9f37a0b5f9739',
    '0x94f4beaf957a603d631c71425a3abc45dec36ed6',
    '0x4ebd8d899733fa13d7dd94b3dda917f89607f6f0',
    '0x1747ff56b266f590db0045e8391b508e07ab6efa',
    '0xdf8ea01a4c70cd80ad6c0c7cec05dcb7dd2bb548',
    '0x952c60fc0dc4d547c728776b66597ce0e2d118fb',
    '0x2ae87393d7ef5d5f59af801d7673a2e7666e9528',
    '0xafee62beae0b2ba68a90a33c268fd059e4390a64',
    '0xe57955b5ef3e6d6fc94fd4ab11cab15005eebd5b',
    '0x3b24ecbca10e7e27b1a5bc084701cb3d520ee061',
    '0x8af4188c0b20646a8b95a28d1161cff9affd1b49',
    '0xa420d9438a1ae5e070fde4aa224258100d745e1a',
    '0xe934ed63c84547881c68afc57d8ff95420e3357f',
    '0x762baa0d0f7cf36288c9a8fb8c6c39b3d1357436',
    '0xfb47e0bd224fb8543a1701f5e8e20e0f11e8dcb1',
    '0xd383fa47d8e4e540c54e70b1f5df7f214c8a4c71',
    '0x8500b759379296fe00334fbe2e449e89098261ff',
    '0xc07aa797b92d5ed7c2eed2719e8d158da0a1cdd5',
    '0x7a70e9dca37368ecaa1355183549f9285ad3f5fd',
    '0x5423df3bd1c889350dbd7c466fe926c00217fda5',
    '0x88d43fb76a57a7a55a3fbb6597fe9824ced67e0b',
    '0x2c26e35ee1a029499de72f7fd382567c8c6e8a81',
    '0x2c982c97f12b8cddad240120de7456943448bd25',
    '0x3d3b5126a1be1018b77862230e827f3670dd8907',
    '0x239169ad8cf1c402a7eb01fbb03ff3a06f2fa3d3',
    '0xb8db0de715349933affaad1d5a7064976c491a11',
    '0xaebe84cae234966a6fc963973943d1baa296132e',
    '0x8f9aad7e3aab1791356480a1cec1827cfd994cf8',
    '0x1789eb5cfff3104fca8209cc0a0992e901949660',
    '0xf423549f0f2ed68408c5b43de86f5001cf992a36',
    '0x83f6b09c7b0cc7c877606998bfbe5c884edca654',
    '0x196c8cbcdda2821d39e1ff8f0b054f58ba784a64',
    '0xc0ecf2546f5fe83d63bdb5e6a9d1488019848fb9',
    '0x0f52e7cb64d468e10a3769b44fb2ebddcc674d9d',
    '0x6f1c20c51073672600af6f05d1b5a38e8d25ee6b',
    '0x3e331d1fbba5db7cc039457172775f424f6903f3',
    '0xbaedfe1817195a306f5677878bd68dd33ea895ee',
    '0x1300be3727ddebe75c6840af8096ca4ca1ef550c',
    '0xe5290e06d984865d5992abba9fa10f267843553f',
    '0x528bbda4906e8c90d1f3736715a5291f22d1172a',
    '0x4f141a90644e41c78fec3433f10dfaf40bf94321',
    '0xade6b7d8cef55dcf339ed82298f9806ea3b0ef1d',
    '0xa46f748b78e07c5d730a1f9d8e669dd3d6b78f2e',
    '0x65ea13b61e6eaad3b94f189c335bd4df5fbbf446',
    '0xf9197dc8a16e076a39dd4acf820b32f26ca267e1',
    '0xf0832624896aa45210716b22896f1ea70eae5255',
    '0x8653f7b0105412b2daf76c554606677e7c23aca6',
    '0xefceab0781d3401d9b9c116164afcb6af5aa5911',
    '0x18c9bc3a38a2e04beee4b160033ef7359ce2805e',
    '0x604dad0f1f6e1500e24b1135ddfa79159d92e8bb',
    '0x3a034512dce4d978908ad4be8116f11bb6e47d12',
    '0x642f7d56a019a01458f271661b5831d4d96745e5',
    '0xb791fdd1a9db3ccf7a3eea3297b08dfac47cca53',
    '0xf63ec1ab6362375b614382c1d14a54aa2932ddd2',
    '0x820f8981bad738a21a02a7238b22ac40042bb18e',
    '0x7c57fb0c9da315c151e2fb5572ab0771a71a46c0',
    '0x4cbf397962863b09264fc55a1affce1f086aeeb5',
    '0x0e27017684a8f3671da40ebb9eb5e0e99495d7f1',
    '0x81aa58828e8c620c58875cebf1f4ae14ddc57a29',
    '0xdc979e7510df975882856b6cbf5baa52f3fde99c',
    '0xce4a621ca6cfb8842e49a2ddc988dd9d9cfc2880',
    '0xc039779dd3855b0364365f3835fbd882fbdabf41',
    '0x7668af6dc5d6bb1b426b75ea49b56f1738257c4e',
    '0xe6cd38fb0b141791310266f5719d77226efe1e0c',
    '0x421638f1b6becf04ae1a13b5d9f49713d85121f6',
    '0x5e8b4ceabfa11b37576cb5af1a567ebead310222',
    '0x7c36dd7ca1c96550729eb14e3eef0a6c6dff25ee',
    '0x5e1dc8b605ef123a9c5521cc2cfa6f1022b2cb14',
    '0xe0dd4f03f00534e9961140769c5afd6abf354e3e',
    '0x2ad4d5a0064af8b2b1dd17d15e1ba4e50713ad01',
    '0xade6d608c9bbb00831a620985af79102fa7bf70d',
    '0x40bc516d7008dd0dcad266826602e48a3de1ff40',
    '0x464dfd581576e434fdd04e3aafe134d055261e1a',
    '0x9553bf77cefea13df3f9bb9924d1a419c1f839c8',
    '0xdd4ef54b909b5832a5a97e61b2cc1e9e2c6c5917',
    '0x8cb70008f3c9f02014bec1a75dc03cdddfb6efae',
    '0x847802368e0458ed65ad8dcc1951899439c10144',
    '0x98083215035a9e79febb8a83ffa6988d7afc59a3',
    '0x74831a35fc4d52e310ec6414eefb037f6fc27ec7',
    '0xfd9a6b292ce907b173f1df4bd2b84feacf8efe1d',
    '0x6c18934607927de7001920519f4394560cc41dac',
    '0x22f09cb9349571820fe7efb374d010aea0027996',
    '0x68c37ce5fb223733850f1a3bc5723e035fb0119d',
    '0xad3e6b9896170bd4f03e4d51676cb963d96fdfcf',
    '0x439a98e1b183c04f3cdab5006ca335d7da55ce23',
    '0x72d59b1d8c33122c89ff234e50db07d4bb6a4676',
    '0x0c37855f899f2d78bd509a5aca50be4f055bb15c',
    '0xe56d26d8fd2eb7ec975e192f9e935170ae95938b',
    '0x275b6e3c955c382026576df2eca9d20f2aee0365',
    '0x5a141d236393dbdb84b9e4deec9f0d48f571a250',
    '0x33c9fa1aec50e3f89e596023dff62e92cd60d8b9',
    '0x9bfa31167acf7845d35bff04f06b3a25117ce776',
    '0x087cdae4009af7974fb808cd4e01414ebf820ab0',
    '0x0884be9557f465778606a8858639bc7d95306cef',
    '0x4f8c323e77a9cbd42b271f7f943ff0c417553e7a',
    '0x27a4d93c7be99b89dffc0424e81a1c4b5f4778dc',
    '0x87786f37f51b88ead283a3d9107d2f777c314727',
    '0x7058e2573bce53b4e320314a909ccb8b0a796eab',
    '0x00266df4e27779428824cf3118903342b772a454',
    '0xa8dd0576b0564f05333432a1c7992fa85c5b483c',
    '0x5bdee923828ced905f20b5aafabf62826e75b42f',
    '0xaf19a16b81f7d740b921b27e814e220ddbadf815',
    '0x0f4f26acb4a7c2ba4da29beb66dc510c23c704f2',
    '0xb39db516fc983a4e4b1aa8a3db3f68bdc0ee976f',
    '0xd029a085aa941ad578b346efa084049f670058b6',
    '0x610a7213745e872ffa325dad25574d9edf92d1cf',
    '0x0ad16c6cd05583868f5fe6da21ada3bbd981c844',
    '0x75a34d4dec3cb35758cf6f441f16d85be93c8cc5',
    '0x137289437909782ade25d4086a833758534d62a3',
    '0x6ea2c8bcc87d5144acf38ff1da61b57ede3e420f',
    '0xebeac7773ad5d087062f4a2391ae516add54d704',
    '0x729b082721b8a78339cd73d66a86f5f44d244a3e',
    '0xbe9cfd199af2e3203a9740be0a5840d07b2f6042',
    '0xf5f0defb5a520632da347af738a5860dc03bee36',
    '0xbe403e95c4a2532fc51dcf6b020f019d3b98deee',
    '0x1c1620fd389ad822d719af2af6e8ec19a8fc8dfb',
    '0x77cbf83a985a131839f135224c0dcb4a358c1192',
    '0x2e90af5f41bdf5b0504a5e85d2a50a8b277e932e',
    '0x8c138386272e1c703fc3648fd32858b4b3f41b1d',
    '0xba0e04e63e58ff54b81fd4ed26ba191e2e5cf357',
    '0x61fb776aad0b574f7783f26f0f9eedc53cfdc3f5',
    '0xc4fb42e0a6e430328d0c5c01b303063682072b65',
    '0xb2f9b85b8ad7e2ba37c076501b03b842c1409878',
    '0x767ecc503d92acf8a8d0916fe4cd8854e63e386a',
    '0x288ac24685d7f5d2e75356b75ad0967e3ab3f425',
    '0x08ef1a1b6159e6f897fbb2c0e638c4561df723c1',
    '0x58c396c2c42c2f41e3e15731770fe3c9bca8d283',
    '0xd77d28170b598a0ef63ddd5fd46afcca99c0fb7e',
    '0xa0d14d5e70b9c2bac2fca55a78b37d94411e9f3c',
    '0x7bed8cf5e977545fc0c069cf73cf0a2a91a41fa1',
    '0x983e941bdf16b5c43635c73be0afdb30ed81ffc4',
    '0x9db58cd82a2e2bd9f680397d2ee8cd69d4348d4b',
    '0x5cfa469349b16e25a33b78e2ab201d0dc792e531',
    '0x1e7471b48b802e6ea4d2d812c1d568e8e733b7e9',
    '0x89eb534e60cf13377eada52e30e1fd725a372b3c',
    '0x2e6e2b18dda2359d033ea2e2a1bfd395a97b5783',
    '0x572b8e86d8251f275a41ee5dcd808cfd3517d39d',
    '0xc92d9a266e58363c809326333dba986eda1406a3',
    '0xbfb138e236270f5e88e2ed55dbe6690bc0cfd218',
    '0xf185b54960ce1c20fe204a5bacc73d63279b90d8',
    '0x9ffa55c14253d8f682b8e900ede841cb55cc0500',
    '0xbbe32e319bb61f84fbc3f8c2bc2448b90c9093e7',
    '0x10d0739e251f5c212312d023c9fcfe0fdcafca23',
    '0x38a9520018d6d6b5ebcf310698a091efb08df1cd',
    '0x98fb555f8d1e76fa5bd4cca524ff09d83de173f1',
    '0x277a1e91e5fa7aecc216153c7bf7791aee9545a0',
    '0x57408cc1a6dd903881f790c6826cfdd433cb2cce',
    '0x2af5fb9ac5abb3b157690ae3b6efe4609deae421',
    '0x026dd7b8e6140c444278c219094004a3032aa6c1',
    '0x19208a280c28ce54edb009a4aef06f3b8af79d8c',
    '0xda7b97c4e24b6be735ff43b2bdcabd00a5968b95',
    '0x7887842c8acbfb250ac7f4bae7eeaa9c0228d9fd',
    '0xcb1972227d7d2a917141125bed7affc6004eebee',
    '0x7a56a216912544673b249883839d59f2a99e9e78',
    '0x089b061ac8a0f182dfce1c9ad5eb396ca268ce53',
    '0x88f028cc25a9c573acec1f12be837c4b986de96a',
    '0x84b2316a326844e7fce78d06d5911190371870e1',
    '0xb74e3feefd937ecac8d0e0a7c5dbe1e2645a875e',
    '0x58b8619ac9ec525a7bbd246550d10154ae4691c1',
    '0xe0f7e8ba9d8e98c99163710df0e65d260923dc64',
    '0x47b2ae6afbaf6bdd91ded4be2b6699f7bd3b9c46',
    '0xc104e2304807ea7ec891f48b175f29b17a544bc6',
    '0xac7000ee47b9ae088012613ef4fb105884924e7b',
    '0xbfe28e2ccb2253ce8ff95cb92a282e3d773a8a25',
    '0x823d4fa36a3605b9e089619e76981561e3e3a664',
    '0x0c3e71152c3cb05628fe34e796139453bb7c0946',
    '0x92e82fe0283b80405ec434ae88cff5f7910372fa',
    '0x28821c8be3c61ff5f88573c34a4c5bfeeb1f8ce1',
    '0xb054b43e82ea8a31583ade129687fd7835e5cbff',
    '0x79a01fb5e7818ad33f74390fc773701d5d40d9e4',
    '0x6f0bdbaa9c5829f0ce85187b4f87d39fe1bb3f4d',
    '0x1e146b79dd6ce54fc26bbf972865547aa8ffb9e5',
    '0x06b8fdaa3ac1218fa1a494803b724b8366e9ea29',
    '0x2e48787f993a9aa86285780620a892717b029152',
    '0x15e8eed1e2ed5d4b10f236b16721cfcbb0b145fc',
    '0x626153a9e538714fe5cbdd9045ec3994e7a50cef',
    '0x49394d1c732ba53d49b721da811f3fd3deded6c3',
    '0x236c29c2295cfc0b4c7cd81ed3cb15832a2f78e5',
    '0x023f7a2e8fda43e81d0d71cac394b0c05c365366',
    '0xdd8170b286d891e9d8c19ff6b06115378c1c2b94',
    '0x14d35b6589c5750db7028abe4c34d30c00482082',
    '0x02d73acc2c609d1fbdf0a1d42e29e406ec8da2bf',
    '0x71a1ef1fb0d6914cb5c73d24e50f5ca021df84fb',
    '0x42e67c32a8ce2f28d48390287aa5da6dc5f3a623',
    '0x2bee217336170ceb2aca74ca780a7f6098397381',
    '0x41ea2272acc150f03c6610af33bfb4b6a8aa129a',
    '0x6b90da4b1e97326d8153dc3f03279c4bae5fe01a',
    '0xfda0cd969ec5c07830eacc094bd49c1c0ff85abb',
    '0x320589d24d535938b66dc3490f197191b1db4757',
    '0x1432caa4e47c1b6de9e8debb11c206f913c97e44',
    '0xbb1598e6fdab904f2a62cb2e338ed44b3bf34951',
    '0xd0ceaf426d050eebadd06e890ef025021d1489dc',
    '0xba8a38d8e02f8fe14f29cceb32875117720ae340',
    '0x23034665348791081e746a8d530b30c9f5aa6623',
    '0xffb71c110a9a01e76a3ed76bdfb8dddd7e156eab',
    '0xe0eb4c9be9827e138864d1d9f4ec0ab12bfc5d42',
    '0xd0f8193b4faf3853d746cf4b375d1be512f47e26',
    '0xf67947e003fb32c17d87dd19c274a9828ee489e3',
    '0x8fab5af540f32bc47994e77c5cc8f9b09f647e24',
    '0x58dbb3953af3ed886bc8a8f95d059d0796921003',
    '0x264b7ce3d33bde1d6a2f7a6a48d059f0703e26fe',
    '0x559570dde3d1f7b07e23a6750a7f87cfbda630c7',
    '0xdda6f72df08b1411174b547c2cced174946d6328',
    '0x4725dafd12e183e55624b7cffa6d56560ca3dbe8',
    '0x0fb5481f84f43134a7bc6c7e92d71be646511056',
    '0x3b84927782d95e1083a5457e724830d29ddd7b29',
    '0x9518fb8ab21450df0fcf59dee8444c545f57b206',
    '0x5223f09a7a2c2333b78e705d23591aecf332acc3',
    '0x15bbde4daa8f7121ba357de8742a3f04abd28de2',
    '0x77eea13a1146a77e637a0a5ec181d0bff8629ab4',
    '0xadd900e90a92de684252b9014c07d2ba73de4f81',
    '0xfb9539ed2175f0def9a28d57c21d4f3f2a1f7a5f',
    '0xc8d0ed4554a7afa5c3468fe9f502d5e4053a2d6d',
    '0xf33da9ceb356ad4927811fa123341006ef3476c3',
    '0xc41b7e925914abcd028c140d761536fcdf8508dd',
    '0x5a73c07b0d0c04396ae93d2f23c020c2401dedd5',
    '0x4cc49731936aa84971c63ebf7d8d528b59865972',
    '0x7aa7fa49215e5f56eaf2ef74a531f56c46186d81',
    '0x3e19069ccb91ce9b608051b3f7f502b9409e8414',
    '0x8b5d3d49b8750b0135ab8b7cde46e1e077e22dae',
    '0x1d200349469709381bb2a5a087a646d9daa5ee29',
    '0x729ba0f7adb9ad724cc77056aa931c2ea6f547a0',
    '0xc557570b47bf25ded6c31afc6e5aaa3effcd316d',
    '0xbd9b12332558b6696006fc401d212ce7493b6a7a',
    '0xdebeb480a6e9eac77bbeca783151a4daf58c2849',
    '0x34cee61443ab4db530202f320b50f96f6ce913a1',
    '0x452886c7c0372e4b3b0fb217bea2455978da8789',
    '0x577df979f8aadcd54b931329345ad0baba9fcead',
    '0x843a4db1fcd177ff716bbf5d82fa1d8ac6771331',
    '0x65cd65d6dce663f0b98adefb6b819a1370bf5478',
    '0x4eb5646568f42a4f92bf5b4dbd6fd03688f5c7ca',
    '0xbf0eefe7c274788458a387561fc33d6191ca76b3',
    '0xda5f0ffe0ce5ad59dc25f790cd67e4c03554e617',
    '0x9fe35af29f9cef08707f9f01127e5de92bbde619',
    '0x2127dca6d553203672f9b77aa206ca0b5adf115d',
    '0x8f3d2b859d0dd11a84d547ef7fc29a53282fa824',
    '0x5e1afb66766250c8062f0014e4309c7f8a611449',
    '0x2221bde4f8b46756a565b755bb268a2a19167b2e',
    '0xa319b31f873dbd7c920868ca2bdeec2cbb121c66',
    '0xe5c1a33668d3d2b249c9ddc766d2e3e459347751',
    '0x8ba3c5d20338acc6c37ec75cd3c2cfd10a3b246d',
    '0xd8276041b083f8864b6e8953988c44e3921de2c4',
    '0xe18deed55e8aee65f02d1c662101b8a9d19f5917',
    '0xf2d42a256f3f6012591c7792a3971f4a1bf7bbd3',
    '0x5d18939f660725379aaf3be3f8d1d3ce514f5d61',
    '0x7d1a655f6111c892438abb4a76ab0f6d28d209da',
    '0x87d7dc8ef01a87a527e51c537f2c5e380ac1e1ea',
    '0xcb7e6af8d31bf06d4a7f8052723e78cafa48c59c',
    '0xca9d8bb8acd294d2a7ff5a912039eb2343b05534',
    '0xc4697fe613a5db0c7d944908001cac5967b9a08f',
    '0xfeb3d8581d4de323de16000fc9f07f0ecbdc938f',
    '0x2459dc3df825ffd3a880fad664144e7aea9d6b95',
    '0xf45be77d7420712a0da7dcdf6a1b61f2cf58a225',
    '0xc35f6f8c691a0d8040a298aa35e65596df567579',
    '0xe921b8bd998d406d691d9a1d5a314e3aafbd4d6c',
    '0x0797b1c283523e2f45ee29d1323d0e83631d67dc',
    '0xe7f3c3fb5c3fce285014a8864d400801796727f2',
    '0x1423fa66b01846e212e9330fb3aa34eb5adb05ec',
    '0xc32db8b857355ed9e5e9a0a939a745b6ebbb419b',
    '0xee51a973e924c8b775ac2037af94d69e3ae3fbcc',
    '0x5431060ca2a3d17f87d0625e6bc1c1e81665f779',
    '0xf3959b0932659d621f6b91c74a1133aa3660752f',
    '0x8bec67d1dd14b842a5d6945e176ac538aeae7395',
    '0xb38619712eeb5f2fb9d866dbb0a6e61add6b0340',
    '0xd283723e57659d26ee7db620aece3deccb1b6ca7',
    '0x53abf5b09e16d993ebba38d67dbb085b0031ccda',
    '0x690050731b3c28edd96968d7e5a3f79a3a878c70',
    '0xebff2fa61bb44d25641ef613995a04f83ba65e39',
    '0x9d273d56411a8e8101ed4e0f7c4f5915e89b53a7',
    '0x290937ebbd868ca4163c41bb15016e1dc22b8947',
    '0x89D5860185fF05843529e42Ec4EFAc7F92f13Dd5',
    '0x47838703cA6b48fd736973200349879f2859bf9B',
    '0x55Cf6A5E34ec8FE3A353cC68AFf43e5cF6f4383D',
    '0xB876658756E286869aD54ebA90420b596ed27118',
    '0x465d01B8A285CEA2981a98a4B0CEf6783bE44e4a',
    '0xa6379eD4792d2102e898b097D0A9beFB53740bD4',
    '0x4439096420E1ebfb6d03F9698d7d62197aEf2f72',
    '0x170889ea043EaFa0c80a93E3873c8498c36228E3',
    '0x05C18f3a16a39321c59Db7571958AD11C0528b89',
    '0x823bE8145d16169303650e6c9Bf34BDA93A9f9Df',
    '0x42D912205E17330FeA019fCd95ADC82b4f2eFe00',
    '0x151606433eB16BF31Eb9BBF8f8d3F20730A02569',
    '0xEa8838303F40e345aa481b22E8D4a4C5b2AFF0a3',
    '0x0f8A46EabE2F4c13806f526DCdbb9ba8d638e6cA',
    '0x79D8dd56fD52393BD78CBe9538d9DedfF7D899BB',
    '0x9f625c91b5b681d337a1e3c05d5d003b365cdf6a',
    '0x49d65d719a4b015f2be278c31babd9760fb8d90a',
    '0x2edC3c2e53C0e291fC78023a3DAdCa499eEf1d4F',
    '0x0dA29E8f31046C7162E52066edf78ad56969dDcf',
    '0xf888321e980dcf01dea475029afcbf8af51fbde3',
    '0xf173970cc967f2e08d5fae2e988fba6e95d9e10e',
    '0x6a8d01c884d3047f7f17f123b4126cf509d8dc4e',
    '0x279f136b5b751912ebd4e7f8e4c0682348294f00',
    '0xf0be9161324efb85d45037faca480a55df79f87a',
    '0x085d785f5a03a6ed9b454830721cfca951c37555',
    '0xcfb0ab7af4d425b7755d484d3b390b90047db32c',
    '0xbfb0ab9f838f7d498e43922b11b00f9b4080d095',
    '0xc85c78b9f61744ebf60da473d37b2629e3c55e5a',
    '0x54dc5827b5de3249c3acfe38a76a83de57b41e63',
    '0xeed8098add5b7fe72f274bca4ef8c97198d8172e',
    '0x4d5826066237ddf280e69025aeed78fb034a534f',
    '0xdbc50b04023fd956f13d3f2625df7fbb022b996e',
    '0xce9fc978d7fcb1d78b58b21bb79d064eeee50ec5',
    '0x0bd1e1eafafb74fb10cc461d4fc6e7f0a150fcbb',
    '0xb731ca6e39ec74e418c304e897b38096fb8463e7',
    '0x54e1c817e03a6c3ae934b7e9943b168d783a5566',
    '0xbfeb480177971ca34c7088b0479da12ac4c3031c',
    '0xa2b9372935e7da880c3f277f53e3eb399134b342',
    '0x566f49122333f2b3b16eacdcf6797c9b56187f84',
    '0xc51c06cfc8acd72a16f5a0cde71f4df9fdfe26b5',
    '0x2e9c252c232f0e4ebc8c3637fb24b9bd85b5c509',
    '0x81628516b8c99a79c39bc8435877c257fef3e621',
    '0x3716b329f7599c29eabe3bd07084b07ab9a1d7bd',
    '0xd48b1c469fb95673e9751a7e9b710c1ccc8f09a8',
    '0xd4f6b38673aeb94debcfbe5c0ea94b2fb49b28d6',
    '0xb73318d6b5b5b73ca7da707250f1e2454f971e5a',
    '0x516c0757d2f413737a79c3792ca203d2ee3341d4',
    '0x60437592183bbf9b5748e1ac724bfbf55aba14e1',
    '0xeed37594ae5569d34db06e3b9ec0234083c5e5c0',
    '0x43fe51ef25c6199c427d7f8c651d9accfbe95c2b',
    '0x863e2E6404A9bdE75FBaEd8c70Fa2c177D1378de',
    '0x32FAF8B9FBcDd004614eDfD8D29fB2D05bE0bb29',
    '0xbD2455261F38a63f5F4F36a5E2D25Fb36Ee408E1',
    '0xd56B98c65B9dAD059FBB8ccE5533F5971D7f49D5',
    '0x13184C08F9b6d0EEc5fb097040BEdC2885e3De10',
    '0x9ce23F22869bAf3565d60EE5336786d546DCA9Fa',
    '0x22f02Ce8277710fFd3CFCBA3492df1EBEf0A2F4d',
    '0xdAb8ff1149e1BCA2729Fb62130c1EE8Cb4D2Ea08',
    '0xDc45dCFBe180bDBbE74Ced3eaE8c480C59a73adE',
    '0xC4C21F3d37aBa13358C206fBde0AbfF213B01a64',
    // Add more OG wallets
];

export const FCFS_WALLETS = [
    // Add FCFS wallet addresses here
    '0x9E90A26AD383d4EBa3AB8E23eF43ED6cf0779d23',
    // Add more FCFS wallets
]; 